/* eslint-disable no-useless-computed-key */
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import {Link, Typography} from "@material-ui/core";
import ActionButton from "../button/actionButton";
import {useDataProvider, useNotify} from "react-admin";
import GenericError from "../error/genericError";
import {useEffect, useState} from "react";
import {formatBsb} from "../../utils/formatter";
import {PAYMENT_METHOD_TYPES} from "../../utils/types";
import SuccessTick from "../success/successTick";
import CircularLoader from "../loading/circularLoader";
import LinkBankAccountDialog from '../dialog/linkBankAccountDialog';
import ModalConsumer from '../consumer/modalConsumer';
import Role from "../../utils/role";
import ViewWhitelistBankAccountsDrawer from "../drawer/viewWhitelistBankAccounts";
import ViewNonEvmWallets from "../drawer/viewNonEvmWallets";

const POLY_SCAN_ENDPOINT = 'https://polygonscan.com/address/'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.surface.secondary,
    },
    content: {
        fontSize: '12px',
        width: '100%',
        padding: '24px',
    },
    header: {
        marginBottom: '32px'
    },
    subtitle: {
        marginBottom: '8px'
    },
    sectionContainer: {
        '&:not(:last-child)': {
            margin: '32px 0px 32px 0px',
            overflowWrap: 'anywhere',
            '&::after': {
                content: '""',
                display: 'inline-block',
                cursor: 'pointer',
                width: '110%',
                height: '100%',
                borderBottom: '1px solid #D6D6DF',
                opacity: 0.23,
                marginTop: '32px',
            }
        },

    },
    fieldContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '&:not(:last-child)': {
            marginBottom: '32px'
        }
    },
    fieldDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    fieldHeading: {
        marginTop: 0,
    },
    fieldSubHeading: {
        color: '#FFFFFF',
        opacity: 0.5,
    },
    actionButton: {
        width: 'auto !important',
        marginTop: '4px'
    },
    success: {
        marginBottom: '12px'
    },
    loader: {
        marginBottom: '12px'
    },
    resultContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '60%',
    },
}));

const PaymentMethodsCard = ({customerId, customerName}) => {
    const classes = useStyles();
    const [showReceivablesAccount, setShowReceivablesAccount] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [notFound, setNotFound] = useState(false);
    const [success, setSuccess] = useState(false);
    const [triggerRefresh, setTriggerRefresh] = useState(true);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        if (triggerRefresh) {
            setNotFound(false);
            setLoading(true);
            dataProvider.getOne('paymentMethods', {customerId})
                .then(({data}) => {
                    if (data.error) {
                        if (data.error.status === 404) setNotFound(true)
                        else throw data.error;
                    } else {
                        setData(data);
                        setError(null)
                    }
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                    setTriggerRefresh(false);
                });
        }
    }, [triggerRefresh]);

    let fields = [];

    if (data?.result?.paymentMethods) {
        const {autoMatcherAccount, payId, crypto} = data.result?.["accounts_Admin"]?.[0]?.["paymentMethods"] || {};
        const {accountNumber: receivablesAccountNumber, bsb: receivablesBsb} = autoMatcherAccount || {};
        const {payId: receivablesPayId} = payId || {}
        const stablesWallet = crypto?.wallets?.find((wallet) => {
            return wallet?.network?.uriPrefix === 'ethereum:'
        });
        const receivablesAccountExists = receivablesAccountNumber && receivablesBsb;
        const payables = data.result?.["paymentMethods"]?.["payables"];
        const address = stablesWallet?.address
        const payableAccountsList = payables?.accountFiatMethods?.map(({id, accountName, accountDetailsText}) => ({
            heading: 'Linked account',
            subHeading: accountName,
            value: accountDetailsText,
        })) || [];

        const nonEvmWallets = crypto?.wallets?.filter(wallet => wallet?.network?.uriPrefix !== 'ethereum:') || []
        const whitelistedSourceAccounts = autoMatcherAccount?.whitelistedSourceAccounts || [];

        const payableWalletsList = payables?.externalWallets?.map(({nickName, address}) => ({
            heading: 'Linked wallet',
            subHeading: nickName,
            value: address,
            isUrl: true,
            href: `${POLY_SCAN_ENDPOINT + address}`
        })) || [];
        fields = [
            [
                {
                    heading: 'Receivables account',
                    value: receivablesAccountExists ? showReceivablesAccount ? `${formatBsb(receivablesBsb)} / ${receivablesAccountNumber}` : '••• •••/••••••••' : 'None',
                    actionLabel: receivablesAccountExists ? showReceivablesAccount ? 'Hide' : 'View' : '',
                    disabled: false,
                    action: () => {
                        if (receivablesAccountExists) setShowReceivablesAccount(!showReceivablesAccount);
                    }
                },
                {
                    heading: 'Stables PayID',
                    value: receivablesPayId ? receivablesPayId : 'None',
                    actionLabel: receivablesPayId ? '' : 'Create',
                    disabled: !Role.hasAdminRole(),
                    action: () => {
                        setLoading(true);
                        dataProvider.create('paymentMethods', {
                            customerId,
                            name: customerName,
                            paymentMethodType: PAYMENT_METHOD_TYPES.PAY_ID_RECEIVABLES
                        }, {
                            onSuccess: ({data}) => {
                                setTimeout(() => {
                                    setLoading(false);
                                    if (data.error) {
                                        setError(data.error);
                                        notify('Please wait 5 seconds before trying again', {type: 'warning'});
                                    } else {
                                        setSuccess(true);
                                        setTimeout(() => {
                                            setError(null);
                                            setSuccess(false);
                                            setTriggerRefresh(true);
                                        }, 500);
                                    }
                                }, 1000);
                            },
                        });
                    }
                },
                {
                    heading: 'Stables address',
                    value: address ? address : 'None',
                    isUrl: true,
                    href: address ? `${POLY_SCAN_ENDPOINT + address}` : 'None'
                },
                {
                    value: 'Whitelisted bank accounts',
                    modalActionLabel: 'View',
                    modalAction: {
                        dialog: ViewWhitelistBankAccountsDrawer,
                        args: {whitelistedSourceAccounts: whitelistedSourceAccounts || []}
                    },
                    disabled: whitelistedSourceAccounts?.length === 0
                },
                {
                    value: 'Non EVM addresses',
                    modalActionLabel: 'View',
                    modalAction: {
                        dialog: ViewNonEvmWallets,
                        args: {wallets: nonEvmWallets}
                    },
                    disabled: nonEvmWallets.length === 0
                }
            ],
            [
                ...payableAccountsList,
                {
                    heading: 'Linked account',
                    value: 'New account',
                    modalActionLabel: 'Add',
                    modalAction: {
                        dialog: LinkBankAccountDialog,
                        args: {customerId, onComplete: () => setTriggerRefresh(true)}
                    },
                    disabled: false,
                },

            ],
            [
                ...payableWalletsList
            ]
        ]
    }
    return (
        <Card elevation={0} className={classes.root}>
            <CardContent className={classes.content}>
                <Typography className={classes.header} variant="h5">Payment information</Typography>
                {success ?
                    <div className={classes.resultContainer}><SuccessTick trigger={success} tickSize='130px'/></div> :
                    notFound ? <div className={classes.resultContainer}>
                            <GenericError
                                customMessage={'No payment methods yet.'}
                                customRetryLabel={"Refresh"}
                                onClick={() => setTriggerRefresh(true)}
                                className={classes.error}/>
                        </div> :
                        error ? <div className={classes.resultContainer}><GenericError
                                onClick={() => setTriggerRefresh(true)} className={classes.error}/></div> :
                            loading ? <div className={classes.resultContainer}><CircularLoader trigger={loading}
                                                                                               loaderSize='120px'/>
                                </div> :
                                fields.filter((section) => section.length > 0).map((section, index) => (
                                    <div className={classes.sectionContainer} key={index}>
                                        {
                                            section.map((field, index) => (
                                                <div className={classes.fieldContainer} key={index}>
                                                    <div className={classes.fieldDetailsContainer}>
                                                        {field.heading && <Typography variant="subtitle2"
                                                                                      className={classes.fieldHeading}>{field.heading}</Typography>}
                                                        {field.subHeading && <Typography variant="body2"
                                                                                         className={classes.fieldSubHeading}>{field.subHeading}</Typography>}
                                                        {field.isUrl ?
                                                            <Link target='_blank' color='inherit' variant="link"
                                                                  href={field.href}>{field.value}</Link> :
                                                            <Typography variant="body1">{field.value}</Typography>}
                                                    </div>
                                                    {field.actionLabel &&
                                                        <ActionButton
                                                            disabled={field.disabled}
                                                            className={classes.actionButton}
                                                            size="sm"
                                                            variant={typeof field.actionLabel === 'string' ? 'primary' : 'text'}
                                                            onClick={field.action}
                                                        >
                                                            {field.actionLabel}
                                                        </ActionButton>
                                                    }
                                                    {field.modalActionLabel &&
                                                        <ModalConsumer>
                                                            {({showModal}) => (
                                                                <ActionButton
                                                                    onClick={() => showModal(field.modalAction.dialog, field.modalAction.args)}
                                                                    disabled={field.disabled}
                                                                    className={classes.actionButton}
                                                                    size="sm"
                                                                    variant={typeof field.modalActionLabel === 'string' ? 'primary' : 'text'}
                                                                >
                                                                    {field.modalActionLabel}
                                                                </ActionButton>
                                                            )}
                                                        </ModalConsumer>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))}
            </CardContent>
        </Card>
    );
};

export default PaymentMethodsCard;
