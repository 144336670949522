export const TRANSACTION_TYPES = {
    CREDIT: "CREDIT",
    PENDING_CREDIT: "PENDING_CREDIT",
    CANCEL_PENDING_CREDIT: "CANCEL_PENDING_CREDIT",
    REFUND_PENDING_CREDIT: "REFUND_PENDING_CREDIT",
    DEBIT: "DEBIT",
    PENDING_DEBIT: "PENDING_DEBIT",
    SEND_FIAT: "SEND_FIAT",
    CANCEL_SEND_FIAT: "CANCEL_SEND_FIAT",
    RETRY_SEND_FIAT: "RETRY_SEND_FIAT",
}

export const TRANSACTION_STATUS_LABELS = {
    CREDIT: "COMPLETED",
    PENDING_CREDIT: "PENDING",
    DEBIT: "COMPLETED",
    PENDING_DEBIT: "PENDING",
    REFUND: 'FAILED',
    CANCEL: 'FAILED',
}

export const ACTIVITY_STATUS_FILTERS = {
    COMPLETE: "Completed",
    PENDING: "Pending",
    INCOMPLETE: "Incomplete"
}

export const activityStatusFilterValue = (value) => {
    switch (value) {
        case ACTIVITY_STATUS_FILTERS.COMPLETE:
            return 'COMPLETE';
        case ACTIVITY_STATUS_FILTERS.PENDING:
            return 'PENDING';
        case ACTIVITY_STATUS_FILTERS.INCOMPLETE:
            return 'INCOMPLETE';
        default:
            return undefined;
    }
}

export const TRANSACTION_CATEGORIES = {
    INTEREST: "INTEREST",
    WITHDRAWAL: "WITHDRAWAL",
    DEPOSIT: "DEPOSIT",
    AUTH: "AUTH",
    CAPTURE: "CAPTURE",
    REFUND: "REFUND",
    CANCEL: "CANCEL",
    ADJUSTMENT: "ADJUSTMENT"
}

export const ACTIVITY_TYPES = {
    ONBOARDING: "ONBOARDING",
    WITHDRAWAL: "WITHDRAWAL",
    DEPOSIT: "DEPOSIT",
    PAYMENT: "PAYMENT",
    REFUND: "REFUND",
    SENT: "SENT",
    RECEIVED: "RECEIVED",
}

export const ACTIVITY_TYPE_FILTERS = {
    WITHDRAWAL: "Withdrawal",
    DEPOSIT: "Top Up",
    PAYMENT: "Payment",
    REFUND: "Refund",
    SENT: "Sent",
    RECEIVED: "Received",
}

export const activityTypeFilterValue = (value) => {
    switch (value) {
        case ACTIVITY_TYPE_FILTERS.WITHDRAWAL:
            return 'WITHDRAWAL';
        case ACTIVITY_TYPE_FILTERS.DEPOSIT:
            return 'DEPOSIT';
        case ACTIVITY_TYPE_FILTERS.PAYMENT:
            return 'PAYMENT';
        case ACTIVITY_TYPE_FILTERS.REFUND:
            return 'REFUND';
        case ACTIVITY_TYPE_FILTERS.SENT:
            return 'SENT';
        case ACTIVITY_TYPE_FILTERS.RECEIVED:
            return 'RECEIVED';
        default:
            return undefined;
    }
}

export const ACTIVITIES_VIEW_MODE = {
    ALL: 'All transactions',
    NEEDS_ACTION: 'Needs action',
}

export const activityViewModeValue = (value) => {
    switch (value) {
        case ACTIVITIES_VIEW_MODE.ALL:
            return 'ALL';
        case ACTIVITIES_VIEW_MODE.NEEDS_ACTION:
            return 'NEEDS_ACTION';
        default:
            return undefined;
    }
}

export const PAYMENT_METHOD_TYPES = {
    PAY_ID_RECEIVABLES: "PAY_ID_RECEIVABLES",
    BANK_ACCOUNT_RECEIVABLES: "BANK_ACCOUNT_RECEIVABLES",
    PAY_ID_PAYABLES: "PAY_ID_PAYABLES",
    BANK_ACCOUNT_PAYABLES: "BANK_ACCOUNT_PAYABLES"
}

export const DEPOSIT_CURRENCIES = {
    USD: 'USD',
    AUD: 'AUD',
    USDC: 'USDC',
    USDT: 'USDT',
    BUSD: 'BUSD'
}

export const CUSTOMER_NOTE_TYPES = {
    MANUAL: "MANUAL",
    ACTIVITY: "ACTIVITY"
}
