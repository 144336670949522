import { gql } from "@apollo/client";

export const listCustomersQuery = gql`
query ListCustomers($searchTerm: String, $pagination: PaginationInput!) {
  listCustomers: listCustomers_Admin(searchTerm: $searchTerm, pagination: $pagination) {
    id
    name
    email
    phone
    kycStatus
  }
}
`

export const getCustomerQuery = gql`
query GetCustomer($customerId: String!) {
  customer: customer_Admin(id: $customerId) {
    id
    name
    email
    phone
    kycRef
    dateCreated
    customerType
    status
    kycStatus
    notes {
      id
      customerId
      actor
      note
      createDate
      lastUpdateDate
      noteType
      additionalContext
    }
    address {
      formattedAddress
      id
      structuredAddress {
        addressLine1 
        addressLine2
        locality
        state
        country
        postalCode
      }
    }
    accounts {
      id
      balances {
        AUD {
          availableBalance
          totalInterestAccrued
        }
        USD {
          availableBalance
          totalInterestAccrued
        }
      }
    }
  }
}
`